<template>
  <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="450 450 300 500">
    <path
      :class="parte ==='mano' || parte ==='otro' ? 'part_marked':''"
      d="M598.13,793.81a43.09,43.09,0,0,0-34.83-4.56l0,.08a21.24,21.24,0,0,1-3.37,3.89c-7.24,6.52-9.13,15-11.76,23.52-1.1,3.55-2.69,7-4,10.42l3.1,5,5,.4c-.43,4.78-.83,10-1.39,15.14-.41,3.73-1.57,7.46-1.36,11.14.12,2.08,1.88,5.11,3.67,5.84,3.65,1.5,5.63-1.21,7.11-4.71,0,2.65,0,5.3,0,7.95-.07,2.94,1.11,4.62,4.2,5,3.62.47,5.19-1.41,5.77-4.55a170.25,170.25,0,0,1,4-18.48v17.32c7.21,2.59,10.38-.47,11-10.17.44-6.69,1-13.37,2-20.06,0,4-.14,7.93.05,11.89.15,3.26-.59,7.54,4.3,7.91s6.09-3.84,6.46-7.26c.59-5.43.13-11,.13-16.48,0-4.69-.62-9.51.2-14.06,1.54-8.46,1.87-16.8-.1-25.16Z"
    />
    <path
      :class="parte ==='biceps' || parte ==='otro' || parte ==='todo_brazo'? 'part_marked':''"
      d="M635.73,647.24c.31-2.38.62-4.71.93-7.09.68-5.17,1.63-10.31,2.53-15.45a31.82,31.82,0,0,1,1-3.56,6.8,6.8,0,0,0,.18-.75l.18-1s12.19-56.48,1.67-123.26l0-.15c-8.89,3.93-18.06,7.24-26.95,11.16-9.73,4.29-12.85,13.23-13.91,22.69-1.27,11.29-1.57,22.68-2.09,34a21.1,21.1,0,0,0,1,7.66c2.56,7.6-1.16,14.58-1.61,21.84-.54,9-1.25,18.08,0,26.88A57,57,0,0,1,599,631l.07.06s16.82,14.68,36.62,16.25Z"
    />
    <path
      :class="parte ==='otro'|| parte ==='todo_brazo' ? 'part_marked':''"
      d="M634.56,657.73c.31-3.53.72-7.06,1.17-10.58l-.31,0c-19.8-1.57-36.18-16.11-36.18-16.11l-.2-.17a56.65,56.65,0,0,1-4.25,18.87l-.08.2s24-10.15,39.64,7.57Z"
    />
    <path
      :class="parte ==='antebrazo' || parte ==='otro' || parte ==='todo_brazo'? 'part_marked':''"
      d="M599.27,777.45c2.7-7.31,5-14.79,7.78-22.07s5.9-14.78,9.29-22c7.79-16.53,14.44-33.47,17.56-51.5,1.15-6.68,0-13.72.39-20.56.07-1.25.17-2.34.27-3.59v-.16c-15.61-17.73-39.86-7.82-39.86-7.82l0,.1c-3.36,8.31-7.32,16.35-9.77,24.93-1.91,6.69-1.85,13.93-2.74,20.92-2,15.58-3.62,31.22-6.16,46.71-1.6,9.75-3.54,19-6,28.63l-.11.42s18.78-6.89,29.24,5.83Z"
    />
    <path
      :class="parte ==='muneca' || parte ==='otro' || parte ==='todo_brazo'? 'part_marked':''"
      d="M598.15,793.82a27.47,27.47,0,0,1,1.07-16.31l0-.11c-10.47-12.71-29.35-5.95-29.35-5.95l-.06.17q-.95,3.75-2,7.47a33.66,33.66,0,0,1-4.55,10l-.08.13a43.31,43.31,0,0,1,34.91,4.57Z"
    />
  </svg>
</template>

<script>
export default {
  name: "BrazoDerecho",
  props: ["parte"],
};
</script>
<style lang="sass">
path
  fill: var(--v-gris1-base) !important
  &.part_marked
    fill: var(--v-gris2-base) !important
    stroke: #272727
    stroke-width: 0.5
    stroke-miterlimit: 10
.selected_inner
  path
    fill: var(--v-gris1-base) !important
    &.part_marked
      fill: var(--v-primary-base) !important
      fill: #E4BB6D
      stroke: #000000
      stroke-width: 0.5
      stroke-miterlimit: 10

#body_selector .v-card:hover
  path.part_marked
    fill: #E4BB6D !important
</style>